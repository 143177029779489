import React, { useEffect } from 'react'
import 'feather-icons'

import TopTokenList from '../components/TokenList'
import { TYPE } from '../Theme'
import Panel from '../components/Panel'
import { useAllTokenData } from '../contexts/TokenData'
import { PageWrapper, FullWrapper } from '../components'
import { RowBetween, RowFixed } from '../components/Row'
import { ButtonLight, ButtonFaded } from '../components/ButtonStyled'
import Search from '../components/Search'
import { useMedia } from 'react-use'
import { useHistory, useLocation } from 'react-router-dom'
// import CheckBox from '../components/Checkbox'
// import QuestionHelper from '../components/QuestionHelper'

function AllTokensPage() {
  const allTokens = useAllTokenData()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const below600 = useMedia('(max-width: 800px)')

  // const [useTracked, setUseTracked] = useState(true)

  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const tokenType = queryParams.get('type')

  return (
    <PageWrapper>
      <FullWrapper>
        <RowBetween>
          <RowBetween>
            {tokenType === 'depin' ? (
              <RowFixed>
                <ButtonFaded
                  onClick={() => {
                    history.push('/tokens')
                  }}
                >
                  Top Tokens
                </ButtonFaded>
                <ButtonLight style={{ marginLeft: '8px' }}>DePIN Tokens</ButtonLight>
              </RowFixed>
            ) : (
              <RowFixed>
                <ButtonLight>Top Tokens</ButtonLight>
                <ButtonFaded
                  style={{ marginLeft: '8px' }}
                  onClick={() => {
                    history.push('/tokens?type=depin')
                  }}
                >
                  DePIN Tokens
                </ButtonFaded>
              </RowFixed>
            )}
            {!below600 && <Search small={true} />}
          </RowBetween>
        </RowBetween>
        {/* <AutoRow gap="4px">
          <CheckBox checked={useTracked} setChecked={() => setUseTracked(!useTracked)} text={'Hide untracked tokens'} />
          <QuestionHelper text="USD amounts may be inaccurate in low liquiidty pairs or pairs without ETH or stablecoins." />
        </AutoRow> */}
        <Panel style={{ marginTop: '6px', padding: below600 && '1rem 0 0 0 ' }}>
          <TopTokenList tokens={allTokens} itemMax={50} tokenType={tokenType} />
        </Panel>
      </FullWrapper>
    </PageWrapper>
  )
}

export default AllTokensPage
