import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'

export const client = new ApolloClient({
  link: new HttpLink({
    uri: 'https://graph-cache.mimo.exchange/subgraphs/name/mimo/mainnet',
    // uri: 'http://127.0.0.1:4000/graphql',
    // uri: 'https://grafebase-mimo-uu-z.grafbase.app/graphql',
    // uri: 'https://mimov2-graphql-cache.onrender.com/graphql',
    batchInterval: 1000,
    batchMax: 100,
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const healthClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://block.mimo.exchange/graphql',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

// export const v1Client = new ApolloClient({
//   link: new HttpLink({
//     uri: 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap',
//   }),
//   cache: new InMemoryCache(),
//   shouldBatch: true,
// })

// export const stakingClient = new ApolloClient({
//   link: new HttpLink({
//     uri: 'https://api.thegraph.com/subgraphs/name/way2rach/talisman',
//   }),
//   cache: new InMemoryCache(),
//   shouldBatch: true,
// })

export const blockClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://block-cache.mimo.exchange/subgraphs/name/common/blocks',
  }),
  cache: new InMemoryCache(),
})
